import posthog from 'posthog-js';
import { browser } from '$app/environment';
import type { LayoutLoad } from './$types';
import { QueryClient } from '@tanstack/svelte-query';
import { dev } from '$app/environment';

// export const trailingSlash = 'always';

export const load: LayoutLoad = async () => {
	if (browser) {
		posthog.init('phc_iEEaUAOwn6Ay8gV1JwYhrIrFNk2833O6ccb4DzPIBnw', {
			api_host: 'https://analytics.playpark.se',
			person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
			persistence: 'memory'
		});
		if (!dev) {
			const BASE_URL = 'https://chatwoot.playpark.se';
			const script = document.createElement('script');
			script.src = BASE_URL + '/packs/js/sdk.js';
			script.defer = true;
			script.async = true;

			script.onload = function () {
				(window as any).chatwootSDK.run({
					websiteToken: 'ei8V2GN41VFPQEJ6JTGfTLig',
					baseUrl: BASE_URL
				});
			};

			document.head.appendChild(script);

			(window as any).chatwootSettings = {
				darkMode: 'auto'
			};
		}
	}
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				enabled: browser,
				staleTime: 60 * 1000
			}
		}
	});

	return { queryClient };
};
